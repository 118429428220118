import { Component } from "@angular/core";
import { DaoCameraService } from "../../../services/dao-camera.service";
import { DaoUserService } from "../../../services/dao-user.service";
import { PopoverController, ToastController, NavParams } from "@ionic/angular";
import {
  ImagePicker,
  ImagePickerOptions,
} from "@ionic-native/image-picker/ngx";
import { File } from "@ionic-native/file/ngx";
import {
  Plugins,
  CameraResultType,
  Capacitor,
  FilesystemDirectory,
  CameraPhoto,
  CameraSource,
  CameraDirection,
} from "@capacitor/core";

const { Camera, Filesystem, Storage } = Plugins;

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.scss"],
})
export class CameraComponent {
  arrayType: number = 0;
  arr1: Array<any> = [];
  arr2: Array<any> = [];
  isUserImage: boolean = false;
  userImage: any;
  profileMode: boolean;

  constructor(
    private imagePicker: ImagePicker,
    private popoverController: PopoverController,
    private dao: DaoCameraService,
    private daoUser: DaoUserService,
    private toastController: ToastController,
    private file: File,
    private navParams: NavParams
  ) {
    if (this.navParams.get("data") == "profile") this.profileMode = true;
  }

  ngDoCheck() {
    this.arrayType = this.dao.arrayType;
    this.isUserImage = this.daoUser.isUserImage;
  }

  public async photoOrGallery() {
    // Take a photo
    const capturedPhoto = await Plugins.Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Rear,
      allowEditing: true,
      saveToGallery: true,
      quality: 100,
      correctOrientation: true,
    });

    let image =
      "data:image/" +
      capturedPhoto.format +
      ";base64," +
      capturedPhoto.base64String;
    if (this.isUserImage) {
      this.userImage = image;
      this.daoUser.isUserImage = false;
    } else {
      this.dao.saveImagesTemporary(image, this.arrayType).then((res) => {
        if (res == true) {
          this.presentToast("Imagen cargada con exito");
        } else {
          this.presentToast("Solo puedes cargar maximo 2 imagenes");
        }
      });
    }
  }

  /* async camara() {
     const options: CameraOptions = {
       destinationType: this.camera.DestinationType.DATA_URL,
       targetWidth: 1000,
       targetHeight: 1000,
       quality: 100,
       correctOrientation: true,
     }
     await this.camera.getPicture(options).then((imageData) => {
       let image = 'data:image/jpeg;base64,' + imageData
       if (this.isUserImage) {
         this.userImage = image
         this.daoUser.isUserImage = false
       } else {
         this.dao.saveImagesTemporary(image, this.arrayType).then(res => {
           if (res == true) {
             this.presentToast('Imagen cargada con exito')
           } else {
             this.presentToast('Solo puedes cargar maximo 2 imagenes')
           }
         })
       }
     }).catch(res => console.error("camera error: ", res))
   }*/

  async picker() {
    const options: ImagePickerOptions = {
      maximumImagesCount: 1,
      quality: 90,
      outputType: 1,
    };
    await this.imagePicker
      .getPictures(options)
      .then((results) => {
        let filename = results[0].substr(results[0].lastIndexOf("/") + 1);
        let path = results[0].substr(0, results[0].lastIndexOf("/") + 1);
        this.file.readAsDataURL(path, filename).then((res) => {
          if (this.isUserImage) {
            this.userImage = res;
            this.daoUser.isUserImage = false;
          } else {
            this.dao.saveImagesTemporary(res, this.arrayType).then((res) => {
              if (res == true) {
                this.presentToast("Imagen cargada con exito");
              } else {
                this.presentToast("Solo puedes cargar maximo 2 imagenes");
              }
            });
          }
        });
      })
      .catch((res) => console.error("picker error: ", res));
  }

  close() {
    this.popoverController.dismiss(this.userImage);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });
    toast.present();
  }
}
