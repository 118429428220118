
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { APIConfig } from '../config/api.config'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DaoUserService {

  public isUserImage: boolean
  public currentUser: any

  private userPhoto: BehaviorSubject<any> = new BehaviorSubject<any>([])
  public userPhotoObs: Observable<Array<any>> = this.userPhoto.asObservable()

  constructor(public http: HttpClient,
              public apis: APIConfig) { }


  updateUserData(object) {
    return this.http.put(`${this.apis.PROFILE_UPDATE_URL}`, object)
  }

  getProfile() {
    return this.http.get(`${this.apis.PROFILE_GET_URL}`)
  }

  getPoints() {
    return this.http.get(`${this.apis.POINTS_URL}`)
  }

  setUserImage() {
    this.isUserImage = true
  }

  updateNavbarUserPhoto() {
    this.userPhoto.next(true)
  }

}
