import { Component, OnInit, OnDestroy } from '@angular/core'
import { DaoUserService } from '../../../services/dao-user.service'
import { DaoCartService } from '../../../services/dao-cart.service'
import { DaoAwardService } from '../../../services/dao-award.service'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { flipInXOnEnterAnimation } from 'angular-animations'

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
  animations: [ flipInXOnEnterAnimation({delay: 500, duration: 1500})]
})
export class PointsComponent implements OnInit, OnDestroy {

  points
  user
  lastMoves
  isOffline: boolean

  subController: Subscription

  constructor(private userDao: DaoUserService,
              private cartDao: DaoCartService,
              private awardDao: DaoAwardService,
              private router: Router) { }

  ngOnInit() {
    this.checkNetwork()
  }

  ngOnDestroy() {
    if(this.subController) this.subController.unsubscribe()
  }

  checkNetwork() {
    if (!navigator.onLine) {
      this.isOffline = true
    } else {
      this.getPointsInfo()
    }
  }

  getPointsInfo() {
    this.subController = this.userDao.getPoints().subscribe( (res:any) => {
      this.points = res
      this.lastMoves = this.points.data.lastMoves.slice(0, 3);
    })
  }

  view() {
    if (this.lastMoves.length > 3 ) {
      this.lastMoves = this.points.data.lastMoves.slice(0, 3);
    } else {
      this.lastMoves = this.points.data.lastMoves;
    }
  }

}
