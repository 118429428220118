import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  regForm: FormGroup
  isOffline: boolean
  isLoaded: boolean
  hasResponse: boolean

  constructor(private fb: FormBuilder,
              private auth: AuthService) { }

  ngOnInit() {
    this.checkNetwork()
  }

  checkNetwork() {
    if (navigator.onLine) {
    this.regForm = this.fb.group({
      first_name:   ['', Validators.required],
      surname:      ['', Validators.required],
      email:        ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password:     ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])],
      jobposition:  ['', Validators.required]
    })
    } else this.isOffline = true
  }
  

  save(form) {
    if(navigator.onLine) {
      this.isLoaded = true
      this.auth.register(form.value).subscribe( 
        (res:any) => {
        this.hasResponse = true
        },
        err => {
          this.regForm.reset()
          this.isLoaded = false
        })
    } else {
      this.isOffline = true
    }
  }

}
