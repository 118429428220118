
import { Injectable } from '@angular/core'
import { DaoAwardService } from './dao-award.service'
import { DaoUserService } from './dao-user.service'
import { Cart } from '../config/models'

@Injectable({
  providedIn: 'root'
})
export class DaoCartService {

  public appCart: Cart = {} as Cart

  constructor(private userDao: DaoUserService) {
    this.appCart.storage = []
    this.appCart.hasItems = false
    this.appCart.itemsAmount = 0
    this.appCart.paymentAmount = 0
  }

  addItem(item) {
    this.appCart.storage.push(item)
    this.appCart.itemsAmount++
    this.appCart.paymentAmount = this.appCart.paymentAmount + item.points_required
    this.appCart.hasItems = true
    return this.appCart.itemsAmount
  }

  removeItem(id, item) {
    this.appCart.itemsAmount--
    this.appCart.paymentAmount = this.appCart.paymentAmount - item.points_required
    this.appCart.storage.splice(id, 1)
    return this.appCart.storage
  }

  getAllItems() {
    return this.appCart.storage
  }

  cleanCart(myPoints) {
    this.appCart.storage = []
    this.appCart.hasItems = false
    this.appCart.itemsAmount = 0
    this.appCart.paymentAmount = 0
    this.appCart.userPoints = this.appCart.userPoints - myPoints
  }

}
