
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { APIConfig } from '../config/api.config'

@Injectable({
  providedIn: 'root'
})
export class DaoOpaService {

  constructor(public http: HttpClient,
              public apis: APIConfig) {}

  getSelectList1() {
    return this.http.get(`${this.apis.REQUIREMENTS_URL}`).pipe(
        map( (res:any) => {
            return res
    }))
  }

  getSelectList2(value) {
    return this.http.get(`${this.apis.FIELDS_URL}${value}/eases`).pipe(
        map( (res:any) => {
            return res
    }))
  }

  createOne(object) {
    return this.http.post(`${this.apis.OPA_URL_SINGLE}`, object)
  }

  createMany(object) {
    return this.http.post(`${this.apis.OPA_URL_MULTIPLE}`, object)
  }

}
