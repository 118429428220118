import { Component, OnInit, OnDestroy } from '@angular/core'
import { DaoAwardService } from '../../../services/dao-award.service'
import { DaoCartService } from '../../../services/dao-cart.service'
import { DaoUserService } from '../../../services/dao-user.service'
import { Subscription } from 'rxjs'
import { flipInXOnEnterAnimation, slideInUpOnEnterAnimation	} from 'angular-animations'

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
  animations: [ 
    flipInXOnEnterAnimation({delay: 500, duration: 1500}),
    slideInUpOnEnterAnimation({delay: 100})
  ]
})
export class AwardsComponent implements OnInit, OnDestroy {

  awards: any
  user: any
  hasItems: boolean
  itemsAmount: number = 0
  isOffline: boolean
  isContent: boolean
  subControllerUser: Subscription
  subControllerAward: Subscription

  constructor(private awardDao: DaoAwardService,
              private cartDao: DaoCartService,
              private userDao: DaoUserService) {}

  ngOnInit() {
    this.checkNetwork()
  }

  ngOnDestroy() {
    if(this.subControllerAward) this.subControllerAward.unsubscribe()
    if(this.subControllerUser) this.subControllerUser.unsubscribe()
  }

  checkNetwork() {
    if (!navigator.onLine) {
      this.isOffline = true
    } else {
      this.subControllerAward = this.awardDao.getAllAwards().subscribe( res => {
         this.awards = res
         this.isContent = true
      })
      this.subControllerUser = this.userDao.getProfile().subscribe( res => this.user = res )
    }
  }

  ngDoCheck() {
    this.itemsAmount = this.cartDao.appCart.itemsAmount
    this.hasItems = this.cartDao.appCart.hasItems
  }

  addItem(item) {
    this.cartDao.addItem(item)
  }

}
