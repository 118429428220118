import { NgModule, Component } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Routes, RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from "@angular/http"
import { Platform } from '@ionic/angular'
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { AuthInterceptor, ErrorInterceptor } from './services/interceptor.service'
import { Camera } from '@ionic-native/camera/ngx'
import { ImagePicker } from '@ionic-native/image-picker/ngx'
import { File } from '@ionic-native/file/ngx'
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'
import { MaterialDesignModule } from './config/material.module'

/* UI COMPONENTS */
import { NavbarComponent } from './components/_ui/navbar/navbar.component'
import { NotificationComponent } from './components/_ui/notification/notification.component'
import { NotfoundComponent } from './components/_ui/notfound/notfound.component'
import { FormErrorComponent } from './components/_ui/form-error/form-error.component'
import { CameraComponent } from './components/_ui/camera/camera.component'

/* AUTH COMPONENTS */
import { IndexComponent } from './components/auth/index/index.component'
import { LoginComponent } from './components/auth/login/login.component'
import { ProfileComponent } from './components/auth/profile/profile.component'
import { RegisterComponent } from './components/auth/register/register.component'

/* BL COMPONENTS */
import { AwardsComponent } from './components/pages/awards/awards.component'
import { PointsComponent } from './components/pages/points/points.component'
import { CartComponent } from './components/pages/cart/cart.component'
import { OpaformComponent } from './components/pages/opaform/opaform.component'
import { RatingComponent } from './components/pages/rating/rating.component'
import { HelpComponent } from './components/pages/help/help.component'
import { RulesComponent } from './components/pages/rules/rules.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'

export const routes: Routes = [
  /* AUTH ROUTES */
  { path: "",                                component: IndexComponent,       },
  { path: "login/:type",                     component: LoginComponent,       },
  { path: "register",                        component: RegisterComponent,    },

  /* APP PAGES */
  { path: "pages",                           component: NavbarComponent,      children: [    
  { path: "",                                component: AwardsComponent,      },
  { path: "opas",                            component: OpaformComponent,     },
  { path: "cart",                            component: CartComponent,        },
  { path: "points",                          component: PointsComponent,      },
  { path: "rating",                          component: RatingComponent,      },
  { path: "rules",                           component: RulesComponent,       },
  { path: "help",                            component: HelpComponent,        },
  { path: "profile",                         component: ProfileComponent,     },
  { path: "**",                              component: NotfoundComponent     }
]}]

@Component({
  selector: 'app-root',
  template: `<ion-app><ion-router-outlet></ion-router-outlet></ion-app>`
})
export class AppComponent {
  constructor(private platform: Platform,
              private splashScreen: SplashScreen,
              private statusBar: StatusBar) {
    this.platform.ready().then( () => {
      this.statusBar.styleDefault()
      this.splashScreen.hide()
  })}
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NotificationComponent,
    NotfoundComponent,
    FormErrorComponent,
    CameraComponent,
    IndexComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    AwardsComponent,
    PointsComponent,
    CartComponent,
    OpaformComponent,
    RatingComponent,
    HelpComponent,
    RulesComponent
  ],
  entryComponents: [
    AppComponent,
    NotificationComponent,
    CameraComponent
  ],
  imports: [
    BrowserModule,
    MaterialDesignModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(), 
    RouterModule.forRoot(routes),
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    Camera,
    ImagePicker,
    File,
    CameraComponent,
    AndroidPermissions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
