import { Component, OnInit, OnDestroy } from '@angular/core';
import { DaoUserService } from '../../../services/dao-user.service'
import { DaoCartService } from '../../../services/dao-cart.service'
import { DaoAwardService } from '../../../services/dao-award.service'
import { flipInXOnEnterAnimation } from 'angular-animations'
import { ToastController } from '@ionic/angular'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [ flipInXOnEnterAnimation({delay: 500, duration: 1500})]
})
export class CartComponent implements OnInit, OnDestroy {


  user
  chosenAwards: Array<any> = []
  hasProducts
  paymentAmount
  isOffline: boolean
  wasSent: boolean

  subControllerPoints: Subscription
  subControllerUser: Subscription

  constructor(private userDao: DaoUserService,
              private cartDao: DaoCartService,
              private awardDao: DaoAwardService,
              private toast: ToastController) { }

  ngOnInit() {
    this.checkNetwork()
    this.updatePoints()
  }

  ngOnDestroy() {
    if(this.subControllerUser) this.subControllerUser.unsubscribe()
    if(this.subControllerPoints) this.subControllerPoints.unsubscribe()
  }

  ngDoCheck() {
    this.chosenAwards = this.cartDao.getAllItems()
    if (this.user) this.user.data.userPointsLevel.current_points = this.cartDao.appCart.userPoints
    this.paymentAmount = this.cartDao.appCart.paymentAmount
    if (this.chosenAwards.length !== 0) {
      this.hasProducts = true
    }
  }

  checkNetwork() {
    if (!navigator.onLine) {
      this.isOffline = true
    } else {
      this.subControllerUser = this.userDao.getProfile().subscribe( res => this.user = res )
    }
  }

  makeOrder() {
    if (this.cartDao.appCart.userPoints < this.cartDao.appCart.paymentAmount) {
      this.presentToast()
    } else {
      this.sendOrder()
    }
  }
 
  ridOf(id, item) {
    this.chosenAwards = this.cartDao.removeItem(id, item)
    if (this.chosenAwards.length == 0) this.cartDao.appCart.hasItems = false
  }

  async presentToast() {
    const toast = await this.toast.create({
      message: 'No tienes suficientes puntos. Por favor, elimine algunos productos.',
      duration: 2000
    });
    toast.present()
  }

  sendOrder() {
    this.awardDao.createOrder({details: this.chosenAwards}).subscribe( res => {
      this.cartDao.cleanCart(this.cartDao.appCart.userPoints)
      this.updatePoints()
      this.wasSent = true
    })
  }

  updatePoints() {
    this.subControllerPoints = this.userDao.getProfile().subscribe( (res:any) => this.cartDao.appCart.userPoints = res.data.userPointsLevel.current_points)
  }

}
