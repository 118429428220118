import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Validators, FormBuilder, FormGroup } from '@angular/forms'
import { DaoUserService } from '../../../services/dao-user.service'
import { CameraComponent } from '../../_ui/camera/camera.component'
import { PopoverController, ToastController } from '@ionic/angular'
import { Subscription } from 'rxjs'
import { flipInXOnEnterAnimation } from 'angular-animations'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [ flipInXOnEnterAnimation({delay: 500, duration: 1500})]
})
export class ProfileComponent implements OnInit, OnDestroy {

  myForm: FormGroup
  user
  userImage
  tempUserImage
  userToUpdate: any = {} as any
  isUpdated: boolean
  isOffline: boolean
  isLoaded: boolean
  isPhotoUpdated: boolean
  isUploadingPhoto: boolean
  isPhotoDownloaded: boolean
  subController: Subscription

  constructor(private formBuilder: FormBuilder,
              private dao: DaoUserService,
              private popoverController: PopoverController,
              private toastController: ToastController) { }

  ngOnInit() {
    this.checkNetwork()
  }

  ngOnDestroy() {
    if(this.subController) this.subController.unsubscribe()
  }

  checkNetwork() {
    if (!navigator.onLine) {
      this.isOffline = true
    } else {
      this.loadItem()
      this.makeForm()
    }
  }

  async updatePhoto() {
    this.dao.isUserImage = true
    const popover = await this.popoverController.create({
      component: CameraComponent,
      translucent: true,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps:{ data: "profile" }
    }) 
    popover.present()
    await popover.onDidDismiss().then( (data:any) => {
      this.isUploadingPhoto = true
      this.user.avatar_photo = data.data
      this.dao.updateUserData(this.user).subscribe( res => {
        this.isUploadingPhoto = false
        this.loadItem()
        this.userImage = data.data
        this.isPhotoUpdated = true
        this.dao.updateNavbarUserPhoto()
      })
    })
  }

  update(form) {
    this.isLoaded = true
    this.userToUpdate = form.value
    this.dao.updateUserData(this.userToUpdate).subscribe( res => {
      this.loadItem()
      this.isLoaded = false
      this.presentToast('Usuario fue actualizado con éxito')
    })
  }

  loadItem() {
    this.subController = this.dao.getProfile().subscribe( (res:any) => {
      this.user = res.data
      this.userImage = this.user.avatar_photo
      this.myForm.patchValue(this.user, { onlySelf: true })
      this.isPhotoDownloaded = true
    })
  }

  makeForm() {
    this.myForm = this.formBuilder.group({
      first_name:     ['', Validators.required],
      surname:        ['', Validators.required],
      email:          ['', Validators.required],
      jobposition:    ['', Validators.required]
    })
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    })
    toast.present()
  }

}

