
import { Injectable } from '@angular/core'
import { Component, Inject } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material'


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<NotificationComponent>) {}

  close() {
    this.dialogRef.close()
  }

}

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(public dialog: MatDialog,
              public snackBar: MatSnackBar) {}

  openDialog(data) {
    this.dialog.open(NotificationComponent, {
      width: '80%',
      height: '60%',
      data: data
    })
  }

  openToast(data, errorType) {
    this.snackBar.open(JSON.stringify(data), "ERROR  "+errorType.toString(), {
      duration: 3000,
    })
  }

}