import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit {

  rules = [
    {
      icon: 'kepler-calendar-with-a-clock-time-tools',
      title: 'Regla 1',
      info: 'Los puntos vencerán cada 3 meses el 50% de sus puntos acumulados',
    },
    {
      icon: 'kepler-award-star-with-olive-branches',
      title: 'Regla 2',
      info: 'El juego tiene 3 niveles, iniciarás con el nivel 1 y en la medida que obtengas más puntos podrás incrementar de niveles.',
    },
    {
      icon: 'kepler-coin',
      title: 'Regla 3',
      info: 'Canjear puntos: Para canjear tus puntos deberás completar la totalidad de los puntos que te son asignados en cada premio, recibirás un correo con las instrucciones para reclamar el premio, si no tienes correo electrónico deberás acercarte al área de HSEQ.',
    },
    {
      icon: 'kepler-check-form',
      title: 'Regla 4',
      info: 'La aprobación del reporte estará sujeta a validación del Representante de HSEQ en cada proyecto.',
    },
    {
      icon: 'kepler-validation',
      title: 'Regla 5',
      info: 'La aceptación del registro estará sujeta por el jefe de HSEQ',
    },
    {
      icon: 'kepler-business-card-of-a-man-with-contact-info',
      title: 'Regla 6',
      info: 'El registro podrás realizarlo mediante correo electrónico o número de identificación de tu cédula.',
    },
    {
      icon: 'kepler-shopping-list',
      title: 'Regla 7',
      info: 'Recuerda que todos los campos del OPA son de obligatorio diligenciamiento.',
    },
    {
      icon: 'kepler-lock1',
      title: 'Regla 8',
      info: 'Una vez termine tu relación laboral y te encuentres desvinculado por más de 1 mes, tu estado pasará a “Inactivo”.',
    },
    {
      icon: 'kepler-swearing',
      title: 'Regla 9',
      info: 'El contenido suministrado por parte del participante no deberá ser de carácter racista, político o indecente, de evidenciarse lo contrario se procederá con la inactivación del usuario.',
    },
    {
      icon: 'kepler-line-chart',
      title: 'Regla 10',
      info: 'La puntuación estará sujeta a los valores estimados por el administrador del sitio.',
    },
    {
      icon: 'fas fa-camera',
      title: 'Regla 11',
      info: 'El límite de fotos para el reporte de OPA es de 2 fotografías.',
    },
  ];
  constructor() {

  }

  ngOnInit() {
  }

}