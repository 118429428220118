import { Injectable, NgZone } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Router } from '@angular/router'
import { map, tap, catchError } from 'rxjs/operators'
import { APIConfig } from '../config/api.config'
import { UserLogin, UserRegister } from '../config/models'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router,
              private apis: APIConfig) {}



  getAppToken() {
    return this.http.post(this.apis.APP_TOKEN_URL, this.apis.TOKEN_OBJECT).subscribe( (res:any) => {
      window.localStorage.setItem("opa_app_token", res.access_token)
    })
  }


  login(body: UserLogin) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem("opa_app_token")}`
      })
    }
    return this.http.post(this.apis.AUTH_LOGIN_URL, body, httpOptions).pipe(
      tap( (res:any) => {
        return window.localStorage.setItem("opa_auth_token", res.accessToken)
      },
      err => {
        // what to do if error response
        console.error("login error: ",err)
      })
    )
  }

  register(body: UserRegister) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem("opa_app_token")}`
      })
    }
    return this.http.post(this.apis.AUTH_REGISTER_URL, body, httpOptions).pipe(
      tap( (res:any) => {
        true
      },
      err => {
        console.error("login error: ",err)
      })
    )
  }

  logout() {
    window.localStorage.removeItem("opa_auth_token")
    this.router.navigate([`/`])
  }



}
