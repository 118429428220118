
import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { DaoUserService } from '../../../services/dao-user.service'
import { OfflineService } from '../../../services/offline.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  user: any
  subController: Subscription

  public appPages = [
    {
      title: 'Premios',
      url: '/pages',
      icon: 'kepler-medal-of-award'
    },
    {
      title: 'Mis Puntos',
      url: '/pages/points',
      icon: 'kepler-coin'
    },
    {
      title: 'Observe Pare Actué – OPA',
      url: '/pages/opas',
      icon: 'kepler-forms'
    },
    {
      title: 'Rating',
      url: '/pages/rating',
      icon: 'kepler-games-podium-with-trophy-for-number-one'
    },
    {
      title: 'Reglas del juego',
      url: '/pages/rules',
      icon: 'kepler-diploma-with-a-star'
    },
    {
      title: 'Ayuda',
      url: '/pages/help',
      icon: 'kepler-information'
    },
    {
      title: 'Configuración',
      url: '/pages/profile',
      icon: 'kepler-settings-work-tool'
    }
  ];

  constructor (private auth: AuthService,
               private daoUser: DaoUserService,
               private offline: OfflineService) {}

  ngOnInit() {
    this.subController = this.daoUser.getProfile().subscribe( res => {
      this.user = res
    })
    this.offline.initIndexDB()
    this.daoUser.userPhotoObs.subscribe( res => this.updateData())
  }

  ngOnDestroy() {
    if(this.subController) this.subController.unsubscribe()
  }

  updateData() {
    this.daoUser.getProfile().subscribe( res => {
      this.user = res
    })
  }

  logout() {
    this.auth.logout()
  }
  

}
