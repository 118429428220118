import { Injectable } from '@angular/core'
import { DaoOpaService } from './dao-opa.service'
import { DaoUserService } from './dao-user.service'
import { Observable, forkJoin } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  tempObjects: any
  list_causes: Array<any>
  selectFieldsArray: Array<any>
  selectByFieldIdArray: Array<any>

  constructor(private daoOpa: DaoOpaService,
              private daoUser: DaoUserService) { }

  initIndexDB() {
    this.initDBforOfflineData()
  }

  async initDBforOfflineData() {
    this.daoOpa.getSelectList1().subscribe ( (res:any) => {
      const fileds_obj = {
        index: 1,
        data: res.data.fields
      }
      const causas_obj = {
        index: 1,
        data: res.data.causesBehavior
      }
      var req = indexedDB.open("opa-offline-select-fields", 1)
      req.onupgradeneeded = (event:any) => {
        let db = event.target.result
        db.createObjectStore('data', {keyPath: 'index'})
      } 
      req.onsuccess = (event:any) => {
        let db = event.target.result
        var transaction = db.transaction("data", "readwrite")
        var objectStore = transaction.objectStore("data")
        objectStore.put(fileds_obj).onsuccess = () => {
          // do some 
        }
      }
      var req = indexedDB.open("opa-offline-select-causas", 1)
      req.onupgradeneeded = (event:any) => {
        let db = event.target.result
        db.createObjectStore('data', {keyPath: 'index'})
      } 
      req.onsuccess = (event:any) => {
        let db = event.target.result
        var transaction = db.transaction("data", "readwrite")
        var objectStore = transaction.objectStore("data")
        objectStore.put(causas_obj).onsuccess = () => {
          // do some 
        }
      }
    })
    this.daoUser.getProfile().subscribe ( (res:any) => {
      const obj = {
        index: 1,
        data: res.data
      }
      var req = indexedDB.open("opa-offline-user", 1)
      req.onupgradeneeded = (event:any) => {
        let db = event.target.result
        db.createObjectStore('data', {keyPath: 'index'})
      } 
      req.onsuccess = (event:any) => {
        let db = event.target.result
        var transaction = db.transaction("data", "readwrite")
        var objectStore = transaction.objectStore("data")
        objectStore.put(obj).onsuccess = () => {
          // do some 
        }
      }
    })

  }

  saveOpaObject(item) {
    let time = new Date
    const objDB = {
      timestamp: time,
      data: item
    }
    var req = window.indexedDB.open("opa-offline-forms", 1)
    req.onupgradeneeded = (event:any) => {
      let db = event.target.result
      if (!db.objectStoreNames.contains('data')) db.createObjectStore('data', {keyPath: 'timestamp'})
    }
    req.onsuccess = (event:any) => {
      let db = event.target.result
      var transaction = db.transaction("data", "readwrite")
      var objectStore = transaction.objectStore("data")
      objectStore.put(objDB).onsuccess = (res) => {
        // do some 
      }
    }
  }

  async uploadToServer(items) {
    let arrayWithObservables = []
    await items.forEach((element:any) => {
      arrayWithObservables.push(this.daoOpa.createMany(element.data))
    })
    return await arrayWithObservables
  }

  uploadOfflineOpas(items) {
    return this.uploadToServer(items).then( res => {
      let mergedObservable: Observable<any>
      return mergedObservable = forkJoin(res)
    })
  }

  uploadFromOffline(arr) {
    let formatedArr = new Array
    let opaObject = {opas: []}
    arr.forEach( (el:any) => formatedArr.push(el.data))
    opaObject.opas = formatedArr
    return this.daoOpa.createMany(opaObject)
  }

  clearDataFromOfflineOpas() {
    var self = this
    var req = window.indexedDB.open("opa-offline-forms", 1)
    req.onsuccess = (event:any) => {
      let db = event.target.result
      var objectStore = db.transaction("data", "readwrite").objectStore("data")
      var objectStoreRequest = objectStore.clear()
      objectStoreRequest.onsuccess = res => {
        console.warn("all elements were removed from index db")
      }
    }
  }
  

}
