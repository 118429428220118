import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {

  @Input() errorObject;

  errormsn = {
    required: 'Este campo es necesario',
    minlength: '',
    cards: 'Por lo menos selecciona una causa'
  };
  message;
  constructor() { }

  ngOnInit() {
    if (Object.keys(this.errorObject)[0].includes('min')) {
      this.errormsn.minlength = this.min(this.errorObject.minlength.requiredLength)
    } 
    this.message = this.errormsn[Object.keys(this.errorObject)[0]];
  }

  min(aux) {
    return `Este campo debe tener minimo ${aux} caracteres`
  }

}
