
import { Injectable } from '@angular/core'
import { DaoUserService } from './dao-user.service'

@Injectable({
  providedIn: 'root'
})
export class DaoCameraService {

  public imgArray_1: Array<any> = []
  public imgArray_2: Array<any> = []
  public arrayType: number = 0
  public arrayState = true

  constructor() {}

  async saveImagesTemporary(img, type): Promise<any> {
    this.arrayState = true
    return new Promise((resolve) => {
      if (type == 1) {
        if (this.imgArray_1.length < 2) {
          this.imgArray_1.push(img)
        } else this.arrayState = false
      } else if (type == 2) {
        if (this.imgArray_2.length < 2) {
          this.imgArray_2.push(img)
        } else this.arrayState = false
      }
    resolve(this.arrayState)
    })
  }

  removeImage(id, type) {
    if (type === 1) {
      this.imgArray_1.splice(id, 1)
    } else {
      this.imgArray_2.splice(id, 1)
    }
  }

  setArrayType(id) {
    this.arrayType = id 
  }

}