import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class APIConfig {

  private PRODUTION_MODE      =    true

  private get env(){if(this.PRODUTION_MODE==true){return this.prodServer}else return this.devServer}
  private prodServer          =   "https://api.opamorenito.com.co/"
  private devServer           =   "https://apikepler.gitdevelop.com/"
  

  public APP_TOKEN_URL        =   this.env + 'public/oauth/token'
  public AUTH_LOGIN_URL       =   this.env + 'public/api/v1/auth/login'
  public AUTH_REGISTER_URL    =   this.env + 'public/api/v1/auth/sign_up'
  public PROFILE_GET_URL      =   this.env + 'public/api/v1/user/logged'
  public PROFILE_UPDATE_URL   =   this.env + 'public/api/v1/user/profile'
  public OPA_URL              =   this.env + 'public/api/v1/opas'
  public OPA_URL_SINGLE       =   this.env + 'public/api/v1/opas'
  public OPA_URL_MULTIPLE     =   this.env + 'public/api/v1/opas/massive'
  public AWARD_URL            =   this.env + 'public/api/v1/awards'
  public RATING_URL           =   this.env + 'public/api/v1/dashboard/users-report'
  public RATING_CAUSA_URL     =   this.env + 'public/api/v1/dashboard/causes-report/'
  public ORDER_URL            =   this.env + 'public/api/v1/orders'
  public REQUIREMENTS_URL     =   this.env + 'public/api/v1/opas/requirements'
  public FIELDS_URL           =   this.env + 'public/api/v1/fields/'
  public POINTS_URL           =   this.env + 'public/api/v1/dashboard/user-mobile'
  public RECOVERY_URL         =   'https://opamorenito.com.co/app/#!/forgot-password'

  public TOKEN_OBJECT =       
  { 
    grant_type: "client_credentials",
    client_id: "5",
    client_secret: "6118wXSdkmcpALX6xKhEzP7ATmGrAaHMf0JGu0Ne",
    scope: "" 
  }

}
