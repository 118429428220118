import { Injectable } from '@angular/core'
import { HttpEvent, HttpInterceptor } from "@angular/common/http"
import { Observable, throwError } from 'rxjs'
import { retry, catchError, map } from 'rxjs/operators'
import { APIConfig } from '../config/api.config'
import { ErrorDialogService } from '../components/_ui/notification/notification.component'

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  URL_EXEPTIONS = [
    this.apis.APP_TOKEN_URL,
    this.apis.AUTH_LOGIN_URL,
    this.apis.AUTH_REGISTER_URL
  ]

  constructor(private apis: APIConfig) {}
  
  intercept(request, next): Observable<HttpEvent<any>> {
    if (!this.URL_EXEPTIONS.find( (el) => el === request.url)) {
      let token:any = window.localStorage.getItem("opa_auth_token")
      let header = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      })
      return next.handle(header)
    } else {
      let headerNull = request.clone({
        setHeaders: {}
      })
      return next.handle(headerNull)}
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  private ERROR_401 = "Usario o contraseña son invalidos"
  private ERROR_403 = "Usuario no es válido"
  private ERROR_0   = "Por favor, póngase en contacto con el soporte."

  constructor(public dialog: ErrorDialogService) { }

  intercept(request, next): Observable<HttpEvent<any>> {
    return next.handle(request)
               .pipe(
                  retry(1),
                  catchError((error) => {
                    switch (error.status) {
                      case 401:
                        this.dialog.openToast(this.ERROR_401, 401)
                        break
                      case 403:
                          this.dialog.openToast(this.ERROR_403, 403)
                        break
                      case 0:
                          this.dialog.openToast(this.ERROR_0, "El servidor no está respondiendo")
                        break
                      case 400:
                          this.dialog.openDialog(error.error)
                        break
                      default:
                        let data = {
                          message: error.error.message,
                          error: error.status
                        }
                      this.dialog.openToast(data.message, data.error)
                    }
                    return throwError(error)
                  })
               )
  }

}