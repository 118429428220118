import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service'
import { OfflineService } from '../../../services/offline.service'

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {

  constructor(private auth: AuthService,
              private offline: OfflineService) { }

  ngOnInit() {
    this.auth.getAppToken()
  }

  slideOpts = {
    effect: 'flip'
  };

}
