import { Component, OnInit, OnDestroy } from '@angular/core'
import { DaoAwardService } from '../../../services/dao-award.service'
import * as Highcharts from 'highcharts'
import { Subscription } from 'rxjs'
import { flipInXOnEnterAnimation } from 'angular-animations'

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  animations: [ flipInXOnEnterAnimation({delay: 500, duration: 1500})]
})
export class RatingComponent implements OnInit, OnDestroy {

  isOffline: boolean
  chartHolder
  user_1: any = {}
  user_2: any = {}
  user_3: any = {}
  user_4: any = {}
  user_5: any = {}
  lastMoves: any = {}

  subControllerRating: Subscription
  subControllerCausas: Subscription

  constructor(public dao: DaoAwardService) { }

  ngOnInit() {
    this.checkNetwork()
  }

  ngOnDestroy() {
    if(this.subControllerCausas) this.subControllerCausas.unsubscribe()
    if(this.subControllerRating) this.subControllerRating.unsubscribe()
  }

  checkNetwork() {
    if (!navigator.onLine) {
      this.isOffline = true
    } else {
      this.graficar()
      this.loaderGaficar()
    }
  }

  chartOptions: any = {
    colors: ['#53b1e5', '#54c56f', '#f7bf76', '#dc695f'],
    chart: {
      type: 'column'
    },
    title: {
        text: ''
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Cantidad'
        }
    },
    xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"> <b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: {
        enabled: false
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [
          {name: 'Complacencia', y: 4, color: '#53b1e5'},
          {name: 'Desconocimiento', y: 3, color: '#54c56f'},
          {name: 'Prisa', y: 4, color: '#f7bf76'},
          {name: 'Cansancio', y: 3, color: '#dc695f'}
        ]
    }]
  };

  graficar() {
    this.subControllerRating = this.dao.getRating().subscribe( (data: any) => {
        this.user_1 = data.user_1
        this.user_2 = data.user_2
        this.user_3 = data.user_3
        this.user_4 = data.user_4
        this.user_5 = data.user_5
      }
    );
  }

  loaderGaficar() {
    this.subControllerCausas = this.dao.getCausas().subscribe( (data: any) => {
      this.chartOptions.series = [
        { name: 'Distribución', colorByPoint: true,
          data: data.map( d => ({name: d.name, y: d.total })) 
        }]
      setTimeout(() => {
        this.chartHolder = Highcharts.chart('container', this.chartOptions )
      }, 500);
      });
  }

}
