import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { AuthService } from '../../../services/auth.service'
import { timer } from 'rxjs'
import { DaoUserService } from '../../../services/dao-user.service'
import { APIConfig } from '../../../config/api.config'
import { UserLogin } from '../../../config/models'
import { ToastController } from '@ionic/angular'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formType: string
  isEmail: boolean
  isID: boolean

  emailForm: FormGroup
  IDForm: FormGroup
  isLoaded: boolean

  user: UserLogin
  deviceToken = "b092aa82-e779-a315-8677-210204565998b6ccf2e7-228d-443b-92e8-83ce51ae7eb4"

  constructor(private auth: AuthService,
              private router: Router,
              private apis: APIConfig,
              private fb: FormBuilder,
              private routerLink: ActivatedRoute,
              private toast: ToastController) {}

  ngOnInit() {
    this.checkNetwork()
  }

  checkNetwork() {
    if(navigator.onLine) {
      this.initForms()
    } else if (window.localStorage.getItem("opa_auth_token")) {
      this.router.navigate(['/pages'])
    } else {
      this.presentToast()
    }
  }

  login(form) {
    this.isLoaded = true
    this.user = form.value
    this.user.deviceToken = this.deviceToken
    if (this.formType == "id") {
      this.user.typeSignIn = "cc"
      this.auth.login(this.user).subscribe( 
        res => {
        timer(500).subscribe( () => this.router.navigate(['/pages']))  
        },
        err => {
          this.isLoaded = false
        }
      )
    } else if (this.formType == "email") {
      this.user.typeSignIn = "email"
      this.auth.login(this.user).subscribe( 
        res => {
        timer(500).subscribe( () => this.router.navigate(['/pages']))  
        },
        err => {
          this.isLoaded = false
        }
      )
    }
  }

  recover() {
    window.open(this.apis.RECOVERY_URL, "_blank")
  }

  initForms() {
    this.formType = this.routerLink.snapshot.params["type"]
    if (this.formType == "id") {
      this.isID = true
      this.isEmail = false
      this.IDForm = this.fb.group({
        username:     ['', Validators.compose([
          Validators.required,
          Validators.minLength(5)
        ])],
        password:     ['', Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ])]
      })
    } else if (this.formType == "email") {
      this.isEmail = true
      this.isID = false
      this.emailForm = this.fb.group({
        username:     ['', Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])],
        password:     ['', Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ])]
      })
    }
  }

  async presentToast() {
    const toast = await this.toast.create({
      message: 'Antes de usuar la App en modo offline, hay que hacer registro, o inicia sesión cuando estes en modo online',
      duration: 2000
    })
    toast.present()
  }

}





