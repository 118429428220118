
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { APIConfig } from '../config/api.config'
const moment = require('moment')
declare var require: any

@Injectable({
  providedIn: 'root'
})
export class DaoAwardService {

  constructor(public http: HttpClient,
              public apis: APIConfig) {}


  getAllAwards() {
    return this.http.get(`${this.apis.AWARD_URL}`).pipe(
        map( (res:any) => {
            return res.data
      })
    )
  }

  getRating() {
    return this.http.get(`${this.apis.RATING_URL}`).pipe(
      map( (res:any) => {
        return res.data
      })
    )
  }

  getCausas() {
    return this.http.get(`${this.apis.RATING_CAUSA_URL}`+ moment().format('YYYY') + '/' + moment().format('MM')).pipe(
      map( (res:any) => {
        return res.data
      })
    )
  }

  createOrder(object) {
    return this.http.post(`${this.apis.ORDER_URL}`, object)
  }

}