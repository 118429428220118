import { Component, OnInit, ViewChild } from "@angular/core";
import { DaoOpaService } from "../../../services/dao-opa.service";
import { DaoCameraService } from "../../../services/dao-camera.service";
import { OfflineService } from "../../../services/offline.service";
import { OPA } from "../../../config/models";
import { CameraComponent } from "../../_ui/camera/camera.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PopoverController, ToastController } from "@ionic/angular";
import {
  fadeInDownOnEnterAnimation,
  fadeOutDownOnLeaveAnimation,
  slideInLeftOnEnterAnimation,
  slideInRightOnEnterAnimation,
} from "angular-animations";
import { MatAccordion } from "@angular/material";

@Component({
  selector: "app-opaform",
  templateUrl: "./opaform.component.html",
  styleUrls: ["./opaform.component.scss"],
  animations: [
    fadeInDownOnEnterAnimation(),
    fadeOutDownOnLeaveAnimation(),
    slideInLeftOnEnterAnimation({ delay: 400 }),
    slideInRightOnEnterAnimation({ delay: 400 }),
  ],
})
export class OpaformComponent implements OnInit {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;

  selectedFormStyle1;
  selectedFormStyle2;
  isFormOne: boolean;
  isFormTwo: boolean;

  fields_select_list: Array<any>;
  fields_select_list_2: Array<any>;
  enableSelect: boolean;
  list_2: Array<any>;

  causas_list: Array<any>;
  selected = [];
  img_list_1: Array<any> = [];
  img_list_2: Array<any> = [];

  opaToSave: OPA = {} as OPA;
  opaFormSecure: FormGroup;
  opaFormCause: FormGroup;
  opaFormInsecure: FormGroup;

  hasRecords: boolean;
  opasOffline: Array<any>;
  isOffline: boolean;
  isLoaded: boolean;
  isUploading: boolean;
  isUploadedFromOffline: boolean;
  isPageLoaded: boolean;
  hasCondition: boolean;

  conditionsList = [
    { id: 1, name: "Comportamiento" },
    { id: 2, name: "Condiciones" },
  ];

  constructor(
    private dao: DaoOpaService,
    private fb: FormBuilder,
    private popoverController: PopoverController,
    private cameraDao: DaoCameraService,
    private offline: OfflineService,
    private toast: ToastController
  ) {}

  ngOnInit() {
    console.log(this.img_list_1);
    this.initForms();
    this.loadOpasFromIndexDB();
    if (!navigator.onLine) {
      this.isOffline = true;
    }
  }

  ngDoCheck() {
    this.img_list_1 = this.cameraDao.imgArray_1;
    this.img_list_2 = this.cameraDao.imgArray_2;
  }

  selectNext(value) {
    let id = value.detail.value;
    var result: any = this.fields_select_list.filter((obj) => {
      return obj.id == id;
    });
    this.enableSelect = true;
    if (result.length > 0) this.fields_select_list_2 = result[0].facilities;
  }

  chooseFormOne() {
    this.isFormOne = true;
    this.isFormTwo = false;
    this.selectedFormStyle1 = "rgb(111, 166, 211)";
    this.selectedFormStyle2 = "";
  }

  chooseFormTwo() {
    this.isFormTwo = true;
    this.isFormOne = false;
    this.selectedFormStyle2 = "rgb(111, 166, 211)";
    this.selectedFormStyle1 = "";
  }

  isSelected(i) {
    return this.selected.includes(i);
  }

  selection(i) {
    if (this.selected.includes(i)) {
      this.selected.splice(this.selected.indexOf(i), 1);
    } else {
      this.selected.push(i);
    }
  }

  async loadOpasFromIndexDB() {
    var self = this;
    var req = window.indexedDB.open("opa-offline-forms", 1);
    req.onupgradeneeded = (event: any) => {
      let db = event.target.result;
      if (!db.objectStoreNames.contains("data"))
        db.createObjectStore("data", { keyPath: "timestamp" });
    };
    req.onsuccess = (event: any) => {
      let db = event.target.result;
      var objectStore = db.transaction("data", "readwrite").objectStore("data");
      var objectStoreRequest = objectStore.getAll();
      objectStoreRequest.onsuccess = () => {
        this.opasOffline = objectStoreRequest.result;
        if (this.opasOffline.length == 0) this.hasRecords = false;
        else this.hasRecords = true;
      };
    };
  }

  send(form) {
    this.isLoaded = true;
    this.opaToSave = form.value;
    this.opaToSave.observe_work_photos = this.img_list_1;
    this.opaToSave.what_observe_photos = this.img_list_2;
    this.opaToSave.causes = this.selected.map((e) => ({ id: e }));

    // FORM 1
    if (this.isFormOne) {
      this.opaToSave.report_type = 1;
      this.opaToSave.opa_condition = 1;

      if (navigator.onLine) {
        this.dao
          .createOne(this.opaToSave)
          .subscribe((res) => this.updateForm());
      } else {
        this.offline.saveOpaObject(this.opaToSave);
        this.updateForm();
      }

      // FORM 2
    } else {
      this.opaToSave.report_type = 2;
      this.opaToSave.opa_condition = 2;
      const other = this.opaToSave.causes.findIndex((d) => d.id === -1);
      this.opaToSave.other_cause = null;
      if (other > -1) {
        const { other_cause } = this.opaFormCause.value;
        if (other_cause) {
          this.opaToSave.other_cause = other_cause;
        } else {
          return this.showToast("Falta completar el campo de otro");
        }

        this.opaToSave.causes = this.opaToSave.causes.filter(
          (d) => d.id !== -1
        );
      }

      if (navigator.onLine) {
        this.dao
          .createOne(this.opaToSave)
          .subscribe((res) => this.updateForm());
      } else {
        this.offline.saveOpaObject(this.opaToSave);
        this.updateForm();
      }
    }
  }

  updateForm() {
    this.enableSelect = false;
    this.cameraDao.imgArray_1 = [];
    this.cameraDao.imgArray_2 = [];
    this.selected = [];
    this.loadOpasFromIndexDB();
    this.isLoaded = false;
    this.showToast("Los datos se guardan exitosamente.");
    this.opaFormSecure.reset();
    this.opaFormInsecure.reset();
    this.accordion.closeAll();
  }

  upload() {
    if (navigator.onLine) {
      this.isOffline = false;
      this.isUploading = true;
      this.offline.uploadFromOffline(this.opasOffline).subscribe(
        (response) => {
          console.warn("offline forms were uploaded to the server");
          this.isUploading = false;
          this.offline.clearDataFromOfflineOpas();
          this.loadOpasFromIndexDB();
          this.isUploadedFromOffline = true;
        },
        (error) => {
          this.showToast(
            "Se produjo un error de red durante el proceso de carga. Por favor, intente mas tarde."
          );
        }
      );
    } else this.isOffline = true;
  }

  removeImage(index, type) {
    this.cameraDao.removeImage(index, type);
  }

  async openModal(id) {
    this.cameraDao.setArrayType(id);
    const popover = await this.popoverController.create({
      component: CameraComponent,
      translucent: true,
    });
    return await popover.present();
  }

  initForms() {
    this.loadSelectFileds();
    this.loadCausas();
    this.opaFormSecure = this.fb.group({
      opa_date: ["", Validators.required],
      ease_id: ["", Validators.required],
      field_id: ["", Validators.required],
      observe_work: ["", Validators.required],
      what_observe: ["", Validators.required],
      action_done: ["", Validators.required],
      observations: ["", Validators.required],
    });
    this.opaFormInsecure = this.fb.group({
      opa_date: ["", Validators.required],
      opa_condition: ["", Validators.required],
      ease_id: ["", Validators.required],
      field_id: ["", Validators.required],
      observe_work: ["", Validators.required],
      what_observe: ["", Validators.required],
      action_done: ["", Validators.required],
      observations: ["", Validators.required],
    });

    this.opaFormCause = this.fb.group({
      other_cause: [""],
    });
    this.isPageLoaded = true;
  }

  async loadSelectFileds() {
    indexedDB.open("opa-offline-select-fields", 1).onsuccess = (event: any) => {
      let db = event.target.result;
      var objectStore = db.transaction("data", "readwrite").objectStore("data");
      var objectStoreRequest = objectStore.getAll();
      objectStoreRequest.onsuccess = () => {
        this.fields_select_list = objectStoreRequest.result[0].data;
      };
    };
  }

  async loadCausas() {
    indexedDB.open("opa-offline-select-causas", 1).onsuccess = (event: any) => {
      let db = event.target.result;
      var objectStore = db.transaction("data", "readwrite").objectStore("data");
      var objectStoreRequest = objectStore.getAll();
      objectStoreRequest.onsuccess = () => {
        this.causas_list = objectStoreRequest.result[0].data;
      };
    };
  }

  selectCondition(ev) {
    if (ev == 2) this.hasCondition = true;
    else this.hasCondition = false;
  }

  async showToast(message: string) {
    const options = await this.toast.create({
      message,
      duration: 2000,
    });
    options.present();
  }
}
